<template>
  <div class="password">
    <el-form
      :model="Form"
      :rules="rules"
      ref="form"
      label-width="100px"
      status-icon
      :validate-on-rule-change="true"
    >
      <el-form-item label="新密码:" prop="pass">
        <el-input v-model="Form.pass" placeholder="新密码" show-password>
        </el-input>
      </el-form-item>
      <el-form-item label="再输入一遍:" prop="confirmPass">
        <el-input
          v-model="Form.confirmPass"
          placeholder="再输入一遍"
          show-password
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm" class="btn-login" 
          >提交</el-button
        >
      </el-form-item>
      <el-form-item> </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { reactive ,getCurrentInstance, ref} from "vue";
import {confirm} from '../http/password'
import { ElMessage } from 'element-plus';
import router from "../router/index.js";
export default {
  name: "Password",
  setup() {

    const {ctx}  = getCurrentInstance()
    const form = ref(null)
     const Form = reactive({
      pass: "",
      confirmPass:'',
    });
     var validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入密码'));
        } else{
          callback()
        }
      };

      var validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== Form.pass) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };

   
    const rules = reactive({
      pass: [{  validator: validatePass, trigger: ["blur"] }],
      confirmPass: [{  validator: validatePass2, trigger: ["blur"] }],
    });


    //提交
    function submitForm(){
        form.value.validate(valid=>{
          if(valid){
            const state = ctx.$store.state
            confirm(state.account,state.userName,state.oldPwd,Form.pass).then((res)=>{
              let data = res.data
              let state = data.state
              if(state==0){
                ElMessage.success('修改密码成功')
                router.push('/')
              }else if(state == 1){
                  ElMessage.error(data.message)
              }
            }).catch((err)=>{
              ElMessage.warning('修改失败')
            })
          }else{

          }
        })
    }

    return {
      Form,
      rules,
      form,
      submitForm
    };
  },
};
</script>

<style scoped>
.password{
  width: 450px;
  margin: 0 auto;
  border: 1px solid #efefef;
  border-radius: 10px;
  padding: 20px;
}
.btn-login{
  width:100%
}
</style>
