import $http from './index'


export function confirm(account,userName,oldPwd,newPwd){
    return $http.post('/User/updateUser',{
        account,
        userName,
        isChangePwd:true,
        oldPwd,
        newPwd
    })
}